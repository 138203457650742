@use 'global/colors';
@use 'global/vars';

.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #{colors.get(neutral, 900, 0.8)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
    backdrop-filter: blur(2.5rem);
    flex-direction: column;

    h1 {
        font-size: 2rem;
        margin: 0;
        color: #{colors.get(neutral, 100)};
    }

    p {
        font-size: 1.4rem;
        color: #{colors.get(neutral, 100)};
    }
}

.icon {
    color: #{colors.get(neutral, 100)};
    margin-bottom: vars.spacer(1);
}
